import { fields } from "@monorepo/utils/src/variables/projectsData/tkGeneration/fields";

export const currentFilters = (): Record<string, string | string[] | boolean | null> => ({
  [fields.OIK]: "",
  [fields.FILE_TYPE]: "",
  [fields.SIGNING]: true,
  [fields.MIN_SIZE]: "",
  [fields.MAX_SIZE]: "",
  [fields.INVENTORY_NUMBER]: "",
  [fields.EAD_NUMBER]: "",
  [fields.APPLICATIONS_NUMBER]: "",
  [fields.MAIN_FILE]: null,
  [fields.ADDITIONAL_FILES]: [],
  [fields.IS_CUSTOM_FILE]: false,
  [fields.ADD_MRD]: false,
  [fields.IS_MRD_EXPIRED]: false,
  [fields.IS_HYBRID_SAME_GUID]: false,
  [fields.ADD_CASE]: false,
  [fields.ADD_PAPER_CASE]: false,
  [fields.TRUSTED_CERT]: [],
  [fields.REPRESENTATIVE_CERT]: [],
  [fields.PREFACE_REQUIRED]: false,
});

export const currentERCProtocolsFilters = (): Record<string, string | string[] | boolean> => ({
  [fields.FILTERS_ERC_PROTOCOLS_OIK]: "",
  [fields.FILTERS_ERC_PROTOCOLS_FILE_TYPE]: "",
  [fields.FILTERS_ERC_SIGNING]: true,
  [fields.FILTERS_ERC_PROTOCOLS_MIN_SIZE]: "",
  [fields.FILTERS_ERC_PROTOCOLS_MAX_SIZE]: "",
  [fields.FILTERS_ERC_PROTOCOLS_INVENTORY_NUMBER]: "",
  [fields.FILTERS_ERC_PROTOCOLS_EAD_NUMBER]: "",
  [fields.FILTERS_ERC_PROTOCOLS_APPLICATIONS_NUMBER]: "",
  [fields.FILTERS_ERC_PROTOCOLS_VERSION]: "",
  [fields.FILTERS_ERC_PROTOCOLS_ID]: "",
  [fields.FILTERS_ERC_ADD_CASE]: false,
  [fields.FILTERS_ERC_ADD_PAPER_CASE]: false,
  [fields.FILTERS_ERC_WITH_HISTORICAL_NOTE]: false,
});

export const currentOIKFilters = (): Record<string, string | string[]> => ({
  [fields.OIK_GENERATE_TEXT]: "",
  [fields.OIK_GENERATE_CODE_RESPONSE]: "",
  [fields.OIK_GENERATE_TK]: "",
});

export const currentInvFilters = (): Record<string, string | string[]> => ({
  [fields.INVENTORY_FILTER]: "",
});

export const currentNomenclatureFilters = (): Record<string, string | string[] | boolean> => ({
  [fields.FILTERS_NOMENCLATURE_OIK]: "",
  [fields.FILTERS_NOMENCLATURE_YEAR]: "",
  [fields.FILTERS_NOMENCLATURE_SECTION]: "",
  [fields.FILTERS_NOMENCLATURE_CASE]: "",
  [fields.FILTERS_NOMENCLATURE_SIGNING]: true,
  [fields.FILTERS_NOMENCLATURE_SUBSECTIONS]: true,
});

export const currentNsiFilters = (): Record<string, string | string[] | boolean> => ({
  [fields.FILTERS_NSI_TK]: "",
  [fields.FILTERS_NSI_STATUS]: "",
});
